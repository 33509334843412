<template>
  <v-list-item two-line :to="{ name: 'modelo', params: { modeloid: modelo.id } }">
    <v-list-item-avatar>
      <v-icon large>mdi-chart-bell-curve</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ modelo.nome }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ modelo.creator }}, {{ formattedDate(this.modelo.created) }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import dateFmt from '../mixins/dateFmt'

export default {
  props: {
    modelo: Object,
  },

  mixins: [dateFmt],

};
</script>
