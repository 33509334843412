<template>
  <v-card>
    <v-overlay
      v-if="loading"
      absolute
      class="text-center">
      <v-progress-circular
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-card-title>
      <v-icon large class="mr-2">mdi-thumb-up-outline</v-icon> Avaliações
      <v-spacer />
      <v-btn
        v-if="false"
        x-small
        outlined
        fab
        color="indigo"
        @click="reavaliar"
        class="mr-2"
      >
          <v-icon>mdi-sync</v-icon>
      </v-btn>
      <NovaAvaliacao
        v-if="!analise.avaliado_por_mim && !analise.avaliacao_definitiva"
        :analise="analise"
      />
    </v-card-title>
    <v-card-text>
      <v-container>

        <v-row v-for="(m, i) in avaliacoes.avaliacoes" :key="i">
          <v-col>
            <!-- <Avaliacao :avaliacao="m" :obfuscado="!analise.avaliado_por_mim" /> -->
            <Avaliacao
            :avaliacao="m"
            :analise="analise"
            :obfuscado="false"
            :nome="nome"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn text
        :disabled="avaliacoes.avaliacoes_prev === null"
        @click="fetchData(avaliacoes.avaliacoes_prev)"
      >
        <v-icon>mdi-chevron-left</v-icon> Anterior
      </v-btn>
      <v-spacer></v-spacer>
      {{ avaliacoes.avaliacoes_pg }} / {{ avaliacoes.avaliacoes_tot_pgs }}
      <v-spacer></v-spacer>
      <v-btn text
        :disabled="avaliacoes.avaliacoes_next === null"
        @click="fetchData(avaliacoes.avaliacoes_next)"
      >
        Próximo <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

import Avaliacao from './Avaliacao'
import NovaAvaliacao from './NovaAvaliacao'

export default {
  components: {
    Avaliacao, NovaAvaliacao
  },

  created () {
    this.fetchData()
  },

  data: () => ({
    loading: true,
    error: null,
  }),

  props: {
    analise: Object,
    nome: String,
  },

  computed: {
    ...mapState(['avaliacoes', 'account']),
  },

  methods: {
    fetchData (url) {
      this.loading = true
      this.error = null

      this.$store.dispatch('avaliacoes/get_avaliacoes', { analise: this.analise.id, url: url })
      .then(() => {
        this.$emit('avals_data')
        this.loading = false
      })
      .catch((error) => {
        this.error = error.response.data
      })
    },

    reavaliar() {
      this.$store.dispatch('analises/reaval_analise', this.analise.id )
      .then(() => {
        this.fetchData()
      })
      .catch((error) => {
        this.error = error.response
      })
    },
  }

};
</script>
