<template>
  <v-card>
    <v-card-title>Análises</v-card-title>
    <v-overlay
      v-if="loading"
      absolute
      class="text-center">
      <v-progress-circular
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <div>
      <div v-for="(s, i) in analises.analises" :key="i">
        <AnaliseListItem :analise="s">
        </AnaliseListItem>
        <v-divider
          v-if="i + 1 < analises.analises.length"
          :key="i"
        ></v-divider>
      </div>
    </div>
    <v-card-actions>
      <v-btn text
        :disabled="analises.analises_prev === null"
        @click="fetchData(analises.analises_prev)"
      >
        <v-icon>mdi-chevron-left</v-icon> Anterior
      </v-btn>
      <v-spacer></v-spacer>
      {{ analises.analises_pg }} / {{ analises.analises_tot_pgs }}
      <v-spacer></v-spacer>
      <v-btn text
        :disabled="analises.analises_next === null"
        @click="fetchData(analises.analises_next)"
      >
        Próximo <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

import AnaliseListItem from './AnaliseListItem'

export default {
  components: {
    AnaliseListItem,
  },

  created () {
    this.fetchData()
  },

  data: () => ({
    loading: false,
  }),

  computed: mapState([
    'analises', 'account',
  ]),

  methods: {
    async fetchData (url) {
      this.loading = true
      await this.$store.dispatch('analises/get_analises', url)
      this.loading = false

    }
  }

};
</script>
