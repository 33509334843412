<template>
  <v-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ on }">
      <v-btn x-small outlined fab color="indigo" v-on="on"><v-icon>mdi-content-duplicate</v-icon></v-btn>
    </template>
      <v-card>
        <v-card-title>
          Clonar Modelo
        </v-card-title>
        <v-card-text>
        <v-form>
          <v-text-field
            v-model="nome"
            label="Nome do Novo Modelo"
            :error-messages="errors.nome"
          ></v-text-field>
          <v-textarea
            rows="20"
            outlined
            v-model="parametros"
            label="Parâmetros do Novo Modelo"
            :error-messages="errors.parametros"
          ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="dialog = false">Cancelar</v-btn>
          <v-btn text @click="createModelo()">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  props: {
    modelo: Object,
  },

  data: function() {
    return {
      dialog: false,

      nome: `Clone de ${this.modelo.nome}`,
      parametros: JSON.stringify(this.modelo.parametros, null, 2),

      errors: {
        nome: null,
        parametros: null,
      },

      non_field_errors: null,
    }
  },

  computed: {
  },

  methods: {
    createModelo: function() {
        this.errors = {}
        this.non_field_errors = null

        let parsed_parms = null
        try {
          parsed_parms = JSON.parse(this.parametros)
        } catch {
          this.errors.parametros = "JSON inválido!"
          return
        }
        
        this.$store.dispatch('modelos/new_modelo',
          {
            nome: this.nome,
            params: parsed_parms,
            tipo: this.modelo.tipo_de_modelo,
            ativo: true,
            modulo: this.modelo.modulo,
          })
        .then(() => {
          this.dialog = false
        })
        .catch((error) => {
          this.errors = error.response.data
          this.non_field_errors = error.response.data.non_field_errors
        })
    },
  },

};
</script>
