<template>
  <div>
    <v-progress-circular v-if="isLoading || !avalData" indeterminate class="mt-2"/>
    <div v-else>
      <v-card v-if="avalData && avalData.contents" elevation="3" class="pa-1 mt-2">
        <v-simple-table dense >
          <thead>
            <tr>
              <th>ID</th>
              <th>Nome</th>
              <th>Cidade</th>
              <th>CPF</th>
              <th>Criado em</th>
              <th>Finalizado?</th>
              <th>PEP?</th>
              <th>Combinações CGU</th>
              <th>Combinação gabinete</th>
              <th>Combinação parceiro</th>
              <th>PEPs parceiro</th>
              <th>Possíveis parentes por cidade</th>
              <th>Possíveis parentes por estados</th>
              <th>Possíveis parentes por outros estados</th>
              <th>Possíveis parentes fora do estado</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ avalData.contents.id  }}</td>
              <td>{{ avalData.contents.name  }}</td>
              <td>{{ avalData.contents.city  }}</td>
              <td>{{ avalData.contents.cpf  }}</td>
              <td>{{ avalData.contents.created ? formattedDate(avalData.contents.created) : ''  }}</td>
              <td>{{ avalData.contents.finished  }}</td>
              <td>{{ avalData.contents.is_PEP }}</td>
              <td>{{ avalData.contents.cgu_matches  }}</td>
              <td>{{ avalData.contents.gabinete_match  }}</td>
              <td>{{ avalData.contents.partner_matches  }}</td>
              <td>
                <span>
                  <showModal 
                    title="Conteúdo"
                    :list="avalData.contents.pep_partners.results"
                    button="Mostrar"
                  />
                </span>
              </td>
              <td>
                <span>
                  <showModal 
                    title="Conteúdo"
                    :list="avalData.contents.possible_pep_relatives_by_city.results"
                    button="Mostrar"
                  />
                </span>
              </td>
              <td>
                <span >
                  <showModal 
                    title="Conteúdo"
                    :list="avalData.contents.possible_pep_relatives_by_state.results"
                    button="Mostrar"
                  />
                </span>
              </td>
              <td>
                <span>
                  <showModal 
                    title="Conteúdo"
                    :list="avalData.contents.possible_pep_relatives_other_states.results"
                    button="Mostrar"
                  />
                </span>
              </td>
              <td>
                <span>
                  <showModal 
                    title="Conteúdo"
                    :list="avalData.contents.possible_pep_relatives_other_states.results"
                    button="Mostrar"
                  />
                </span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
      <v-card v-else>
      <v-simple-table dense class="mt-2">
        <thead>
          <tr>
            <th>
              Nada consta
            </th>
          </tr>
        </thead>
      </v-simple-table>
    </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import dateFmt from '../mixins/dateFmt'
import showModal from '../components/Modal.vue'

export default {
  props: {
    avalId: Number,
  },

  mixins: [
    dateFmt
  ],

  computed: {
    ...mapState(['avaliacoes'])
  },

  components: {
    showModal
  },

  data() {
    return {
      isLoading: true,
      avalData: null
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    init () {
      this.isLoading = true
      this.avalData = null
      
      this.$store.dispatch('avaliacoes/get_data', this.avalId, 'Busca Relacionamento PEP - Papyrus').then((data) => {
        this.avalData = data
        this.isLoading = false
      })
    } 
  }
}
</script>
