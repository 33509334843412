<template>
    <div>
        Há pessoas na lista de PEPs da CGU que compartilham sobrenomes com a pessoa avaliada e têm resultados quando procurados conjuntamente no Google. Seguem aqueles dentre os primeiros 80 que tem hits, ordenados primeiro por proximidade geográfica e depois por raridade do(s) nome(s) em comum. Avalie os pares e, caso as pessoas sejam relacionadas, trate a pessoa avaliada como PEP e finalize esta avaliação.
        <v-simple-table>
            <thead>
                <tr>
                    <th>
                        Nome
                    </th>
                    <th>
                        Município
                    </th>
                    <th>
                        Hits
                    </th>
                    <th>
                        Freq. / k
                    </th>
                    <th>
                        Google Search
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                v-for="row in rows"
                :key="row.nome + ',' + row.municipio"
                >
                    <th class="text-body-2">
                        {{ row.nome }}
                    </th>
                    <th class="text-body-2">
                        {{ row.municipio }}
                    </th>
                    <th class="text-body-2">
                        {{ row.hits }}
                    </th>
                    <th class="text-body-2">
                        {{ row.freq_nome }}
                    </th>
                    <th class="text-body-2">
                        <a
                        target="_blank"
                        :href="row.google_search"
                        >
                            <v-icon
                            color="blue darken-2"
                            >
                            mdi-link
                            </v-icon>
                        </a>
                    </th>
                </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>

<script>
export default {
    props: {
        comentario: String,
        nome: String,
    },

    computed: {
        find_match() {
            return /nome\(s\) em comum: (['`'\w\s:À-ÿ(),./]*)\. Avalie/i.exec(this.comentario)
        },

        rows() {
            if (this.find_match) {
                let unparsed_rows = this.find_match[1].split(' / ')
                let row_matches = []
                for (let row of unparsed_rows) {
                    let match = /([\w\s]*): (['`'\w\sÀ-ÿ()]*), (\d*) hits, ([\d.]*)\/mil/i.exec(row)
                    if (match) {
                        let name = this.nome
                        if (name)
                            name = name.slice(3)
                        row_matches.push({
                            'nome': match[1],
                            'municipio': match[2],
                            'hits': match[3],
                            'freq_nome': match[4],
                            'google_search': `https://google.com/search?q="${match[1]}"+"${name}"`,
                        })
                    }
                }
                return row_matches
            }
            else
                return []
        }
    },
}
</script>

<style>

</style>
