<template>
  <div>
    <v-progress-circular v-if="isLoading || !avalData" indeterminate class="mt-2"/>
    <div v-else>
      <v-card v-if="avalData && avalData.results && avalData.results.length > 0" elevation="3" class="pa-1 mt-2">
        <v-simple-table dense >
          <thead>
            <tr>
              <th>ID</th>
              <th>ID CNJ</th>
              <th>Nº Peca</th>
              <th>Nome</th>
              <th>Nome mãe</th>
              <th>Data nascimento</th>
              <th>Sexo</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(c, i) in avalData.results" :key="i">
              <td>{{ c.id  }}</td>
              <td>{{ c.id_cnj  }}</td>
              <td>{{ c.numero_peca  }}</td>
              <td>{{ c.nome  }}</td>
              <td>{{ c.nome_mae  }}</td>
              <td>{{ c.data_nascimento  }}</td>
              <td>{{ c.sexo  }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
      <v-card v-else>
      <v-simple-table dense class="mt-2">
        <thead>
          <tr>
            <th>
              Nada consta
            </th>
          </tr>
        </thead>
      </v-simple-table>
    </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import dateFmt from '../mixins/dateFmt'

export default {
  props: {
    avalId: Number,
  },

  mixins: [
    dateFmt
  ],

  computed: {
    ...mapState(['avaliacoes'])
  },

  data() {
    return {
      isLoading: true,
      avalData: null
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    init () {
      this.isLoading = true
      this.avalData = null
      
      this.$store.dispatch('avaliacoes/get_data', this.avalId, 'Lista Terrorismo EU').then((data) => {
        this.avalData = data
        this.isLoading = false
      })
    } 
  }
}
</script>
