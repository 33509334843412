export default {
  methods: {
    displayStatus: function(status) {
      switch(status) {
        case 'CR':
          return "Criado"
        case 'CP':
          return "Concluído"
        case 'PR':
          return "Em andamento"
        case 'QD':
          return "Aguardando execução"
        case 'OP':
          return "Opcional"
        case 'PD':
          return "Opcional com custo adicional"
        case 'IF':
          return "Dados insuficientes"
        case 'NF':
          return 'Não encontrado'
        case 'FD':
          return 'Erro'
        case 'RT':
          return 'Aguardando nova tentativa'
        default:
          return 'Status desconhecido'
      }
    },
    displayStatusIcon: function(status) {
      switch(status) {
        case 'CR':
          return "mdi-file-document-outline"
        case 'CP':
          return "mdi-check-circle-outline"
        case 'PR':
          return "mdi-wrench-outline"
        case 'QD':
          return "mdi-clock-outline"
        case 'OP':
          return "mdi-file-plus-outline"
        case 'PD':
          return "mdi-cash-usd"
        case 'IF':
          return "mdi-file-hidden"
        case 'NF':
          return 'mdi-help-rhombus-outline'
        case 'FD':
          return 'mdi-alert-circle-outline'
        case 'RT':
          return 'mdi-history'
        default:
          return 'mdi-skull-crossbones-outline'
      }
    },
    displayStatusColor: function(status) {
      switch(status) {
        case 'CR':
          return "grey"
        case 'CP':
          return "green"
        case 'PR':
          return "blue"
        case 'QD':
          return "blue"
        case 'OP':
          return "grey"
        case 'PD':
          return "yellow darken-2"
        case 'IF':
          return "grey"
        case 'NF':
          return 'yellow darken-2'
        case 'FD':
          return 'red'
        case 'RT':
          return 'blue'
        default:
          return 'red darken-2'
      }
    },
  },
}
