<template>
  <div>
    <v-progress-circular v-if="isLoading || !avalData" indeterminate class="mt-2"/>
    <div v-else>
      <v-card v-if="avalData && avalData.contents && avalData.contents.length > 0" elevation="3" class="pa-1 mt-2">
        <v-simple-table dense >
          <thead>
            <tr>
              <th>Nome</th>
              <th>Nome órgão</th>
              <th>Cidade</th>
              <th>CPF</th>
              <th>Data fim carência</th>
              <th>Data início exercício</th>
              <th>Data fim exercício</th>
              <th>Função</th>
              <th>Estado</th>
              <th>Nível função</th>
              <th>Publicações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(c, i) in avalData.contents" :key="i">
              <td>{{ c.nome  }}</td>
              <td>{{ c.nome_orgao  }}</td>
              <td>{{ c.cidade  }}</td>
              <td>{{ c.cpf  }}</td>
              <td>{{ c.data_fim_carencia  }}</td>
              <td>{{ c.data_inicio_exercicio  }}</td>
              <td>{{ c.data_fim_exercicio  }}</td>
              <td>{{ c.descricao_funcao  }}</td>
              <td>{{ c.estado  }}</td>
              <td>{{ c.nivel_funcao  }}</td>
              <td>
                <span>
                  <showModal 
                    title="Conteúdo"
                    :list="c.publicacoes"
                    button="Mostrar"
                  />
                </span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
      <v-card v-else>
      <v-simple-table dense class="mt-2">
        <thead>
          <tr>
            <th>
              Nada consta
            </th>
          </tr>
        </thead>
      </v-simple-table>
    </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import dateFmt from '../mixins/dateFmt'
import showModal from '../components/Modal.vue'

export default {
  props: {
    avalId: Number,
  },

  mixins: [
    dateFmt
  ],

  computed: {
    ...mapState(['avaliacoes'])
  },

  components: {
    showModal
  },

  data() {
    return {
      isLoading: true,
      avalData: null
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    init () {
      this.isLoading = true
      this.avalData = null
      
      this.$store.dispatch('avaliacoes/get_data', this.avalId, 'Consulta de PEP - Papyrus (CGU)').then((data) => {
        this.avalData = data
        this.isLoading = false
      })
    } 
  }
}
</script>
