<template>
  <v-list-item three-line :to="{ name: 'analise', params: { analiseid: analise.id } }">
    <v-list-item-avatar>
      <v-icon large>mdi-account-cash</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ analise.documento }} - {{ analise.display_name || '?' }}
        <v-icon v-if="analise.avaliado_por_mim" color="green" small>mdi-check-circle-outline</v-icon>
        <v-icon v-if="analise.avaliacao_definitiva" color="yellow darken-2" small>mdi-shield-star-outline</v-icon>
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ displayStatus(analise.status) }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        {{ analise.creator }}, {{ displayCreated }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-btn
    v-if="analise.modelo_group"
    x-small
    rounded
    color="primary">
      {{analise.modelo_group.name}}
    </v-btn>
  </v-list-item>
</template>

<script>
import dateFmt from '../mixins/dateFmt'
import displayStatus from '../mixins/displayStatus'

export default {
  props: {
    analise: Object,
  },

  mixins: [dateFmt, displayStatus],

  computed: {
    displayCreated: function() {
      return this.formattedDate(this.analise.created)
    },
  }

};
</script>
