<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on }">
      <v-btn x-small outlined fab color="indigo" v-on="on">
        <v-icon v-if="!avaliacao">mdi-plus</v-icon>
        <v-icon v-else>mdi-square-edit-outline</v-icon>
      </v-btn>
    </template>
      <v-card>
        <v-card-title>
          <span v-if="!avaliacao">Adicionar Avaliação</span>
          <span v-else>Editar Avaliação</span>
        </v-card-title>
        <v-card-text>
          <v-form>
            <div class="d-flex justify-space-between mt-2 mb-5" v-if="PEP && avaliacao"> 
              <tbody>
                <tr>
                  <td>
                    A Pessoa é PEP:
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-radio-group v-model="pep_aval" row>
                      <v-radio
                        :value="true"
                        label="Sim"
                      />
                      <v-radio
                        :value="false"
                        label="Não"
                      />
                    </v-radio-group>
                    <div v-if="pep_aval" class="font-weight-bold red--text">
                      MARCAR PESSOA COMO PEP!
                    </div>
                    <div v-else class="green--text">
                      Marcar pessoa como NÃO PEP
                    </div>
                  </td>
                </tr>
              </tbody>
            </div>
            <div v-else>
              <div class="d-flex justify-space-around mt-2 mb-5">

                <div class="text-center"><v-btn :outlined="resultado != 'A'" fab :color="resultado === 'A' ? 'green' : 'grey'" height="64" width="64" dark
                  @click="resultado = 'A'"
                >
                  <v-icon size="40">mdi-thumb-up-outline</v-icon>
                </v-btn><div class="text-center mt-1">Aprovado</div></div>


                <div class="text-center"><v-btn :outlined="resultado != 'I'" fab :color="resultado === 'I' ? 'yellow darken-2' : 'grey'" height="64" width="64" dark
                  @click="resultado = 'I'; risco=null"
                >
                  <v-icon size="40">mdi-file-question-outline</v-icon>
                </v-btn><div class="text-center mt-1">Insuficiente</div></div>

                <div class="text-center"><v-btn :outlined="resultado != 'P'" fab :color="resultado === 'P' ? 'orange darken-2' : 'grey'" height="64" width="64" dark
                  @click="resultado = 'P'; risco=null"
                >
                  <v-icon size="40">mdi-file-document-edit-outline</v-icon>
                </v-btn><div class="text-center mt-1">Pendente</div></div>

                <div class="text-center"><v-btn :outlined="resultado != 'R'" fab :color="resultado === 'R' ? 'red' : 'grey'" height="64" width="64" dark
                  @click="resultado = 'R'"
                >
                  <v-icon size="40">mdi-thumb-down-outline</v-icon>
                </v-btn><div class="text-center mt-1">Reprovado</div></div>

              </div>
            </div>
              <div class="d-flex justify-space-around mt-2 mb-5">
                <div class="text-center"><v-btn :outlined="risco != 'B'" fab :color="risco === 'B' ? 'green' : 'grey'" height="32" width="32" dark
                  @click="risco='B'"
                >
                  <v-icon size="20">mdi-alert-circle-check-outline</v-icon>
                </v-btn><div class="text-center mt-1">Risco Baixo</div></div>

                <div class="text-center"><v-btn :outlined="risco != 'M'" fab :color="risco === 'M' ? 'yellow darken-2' : 'grey'" height="32" width="32" dark
                  @click="risco='M'"
                >
                  <v-icon size="20">mdi-alert</v-icon>
                </v-btn><div class="text-center mt-1">Risco Médio</div></div>

                <div class="text-center"><v-btn :outlined="risco != 'A'" fab :color="risco === 'A' ? 'red' : 'grey'" height="32" width="32" dark
                  @click="risco='A'"
                >
                  <v-icon size="20">mdi-alert-octagon</v-icon>
                </v-btn><div class="text-center mt-1">Risco Alto</div></div>

              </div>
              <v-textarea
                outlined
                v-model="comentario"
                label="Comentário"
                :error-messages="errors.comentario"
                rows="15"
              ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-checkbox
            class="ml-3"
            label="Avaliação Definitiva"
            v-model="definitivo"
            @click.native="definitivo ? definitivoDialog = true : null"
          ></v-checkbox>
          <v-spacer />
          <v-btn text @click="dialog = false">Cancelar</v-btn>
          <v-btn
            text
            @click="createAval()"
            :disabled="resultado === null || risco == null"
            v-if="!avaliacao"
          >Salvar</v-btn>
          <v-btn
            text
            @click="updateAval()"
            :disabled="resultado === null || risco == null"
            v-else
          >Atualizar</v-btn>
        </v-card-actions>
      </v-card>
      <v-dialog
        persistent
        v-model="definitivoDialog"
        max-width="400"
      >
        <v-card>
          <v-card-title>Avaliação Definitiva</v-card-title>
          <v-card-text>Marcar esta avaliação como definitiva pode desencadear desembolsos para a Upp. Certifique-se de que esta avaliação é correta antes de continuar.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="definitivo = false; definitivoDialog = false">Cancelar</v-btn>
            <v-btn text @click="definitivo = true; definitivoDialog = false">Continuar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
</template>

<script>
export default {
  props: {
    analise: Object,
    avaliacao: Object,
    PEP: Boolean
  },

  data: function() {
     return {
        dialog: false,
        definitivoDialog: false,

        resultado: this.avaliacao ? this.avaliacao.resultado : null,
        risco: this.avaliacao ? this.avaliacao.risco : null,
        definitivo: this.avaliacao ? this.avaliacao.definitivo : false,
        comentario: this.avaliacao ? this.comentario : null,

        errors: {
          resultado: null,
          comentario: null,
        },

        non_field_errors: null,
        pep_aval: false,
      }
  },

  methods: {
    createAval: async function() {
        this.errors = {}
        this.non_field_errors = null

        await this.$store.dispatch('avaliacoes/new_avaliacao',
          {
            resultado: this.resultado,
            risco: this.risco,
            definitivo: this.definitivo,
            comentario: this.comentario,
            analise: this.analise.url,
          })

        this.dialog = false
        this.$store.dispatch('analises/get_analise', this.analise.id)
    },
    updateAval: async function() {
        if (this.PEP) {
          if (this.pep_aval) {
            this.resultado = 'R'
          }
          else {
            this.resultado = 'A'
          }
        }
        // console.log({
        //       resultado: this.resultado,
        //       risco: this.risco,
        //       definitivo: this.definitivo,
        //       comentario: this.comentario,
        //       analise: this.analise.url,
        //       avaliacao: this.avaliacao,
        //     })
        this.errors = {}
        this.non_field_errors = null

        await this.$store.dispatch('avaliacoes/update_avaliacao',
          {
            resultado: this.resultado,
            risco: this.risco,
            definitivo: this.definitivo,
            comentario: this.comentario,
            analise: this.analise.url,
            avaliacao: this.avaliacao,
          })

        this.dialog = false
        this.$store.dispatch('analises/get_analise', this.analise.id)
    },
  },

};
</script>
