export default {
  methods: {
    formattedDate: function(timestamp) {
      let d = new Date(timestamp)
      return d.toLocaleString()
    },
    formattedDate2 (data) {
      if (data.length === 8) {
        const y = data.substring(0, 4)
        const m =  data.substring(4, 6)
        const d = data.substring(6, 8)
        return d + '/' + m + '/' + y
      }
    },
    formattedDate3: function(timestamp) {
      let d = new Date(timestamp)
      return d.toLocaleString().substring(0, 10)
    },
  },
}
