<template>
  <div>
    <AppNav />
    <v-main>
      <ModeloDetails
        v-if="$store.getters['account/loggedin']"
        :modeloid="modeloid">
      </ModeloDetails>
      <v-container v-else fluid grid-list-md>
        <h1>Por favor, faça login para usar o sistema.</h1>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import AppNav from '../components/AppNav';
import ModeloDetails from '../components/ModeloDetails';

export default {
  components: {
    AppNav,
    ModeloDetails,
  },

  props: {
    modeloid: Number,
  },

  computed: mapState([
    'account'
  ]),
};
</script>
