<template>
    <v-container fluid>
      <template v-if="analise">
        <v-row>
          <v-col cols='12' md='6'>
            <!-- <v-row>
              <v-col> -->
            <v-card>
              <v-card-title>
                <v-icon large class="mr-2">mdi-account-cash</v-icon> {{ analise.documento }}{{ displayNome }}
              </v-card-title>
              <v-card-text>
                <v-simple-table dense>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Status</td>
                      <td v-if="analise.avaliado_por_mim">{{ displayStatus(analise.status) }}</td>
                      <td v-else class="indigo--text">Avalie a operação para ver este dado</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">PEP</td>
                      <td :class="`${analise.isPEP?'red':'green'}--text`">{{analise.isPEP ? "SIM" : "NÃO"}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Completo</td>
                      <td :class="`${analise.completed?'indigo':'red'}--text`">{{new Date(analise.completed).toDateString() ? analise.completed : 'Incompleto'}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Model Group</td>
                      <td class="indigo--text">{{analise.modelo_group ? analise.modelo_group.name : 'Padrão'}}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-card>
          <!-- </v-col> -->
            <!-- </v-row> -->
            <v-row>
              <v-col>
                <v-expansion-panels>
                  <v-expansion-panel @click="fetchPxdata()">
                    <v-expansion-panel-header class="font-weight-bold">Resumo PXSearch</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <PXSummary
                        v-if="analise.pxdata"
                        :analise="analise"
                        :loading="loading"
                        @summaryChanged="fetchData"
                      />
                      <v-card v-else>
                        <v-card-title><v-icon large class="mr-2">mdi-shield-search</v-icon> Resumo PXSearch</v-card-title>
                        <v-card-text>
                          <v-overlay
                            absolute
                            opacity="1"
                            color="white"
                            light
                            z-index="4"
                          >
                            <span class="indigo--text title">Documento não encontrado</span>
                          </v-overlay>
                        </v-card-text>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <!-- <PXSummary
                  v-if="analise.pxdata"
                  :analise="analise"
                  :loading="loading"
                  @summaryChanged="fetchData"
                >
                </PXSummary>
                <v-card v-else>
                  <v-card-title><v-icon large class="mr-2">mdi-shield-search</v-icon> Resumo PXSearch</v-card-title>
                  <v-card-text>
                    <v-overlay
                      absolute
                      opacity="1"
                      color="white"
                      light
                      z-index="4"
                    >
                      <span class="indigo--text title">Documento não encontrado</span>
                    </v-overlay>
                  </v-card-text>
                </v-card> -->
          </v-col>
          </v-row>
          </v-col>
        <v-col cols='12' md='6'>
          <Avaliacoes
          :analise="analise"
          :nome="displayNome"
          @avals_data="dataFilter()"
          />
        </v-col>
      </v-row>
    </template>
    <v-card v-else>
      <v-card-text class="text-center">
        <v-progress-circular
          indeterminate
        ></v-progress-circular>
      </v-card-text>
    </v-card>
    <v-expansion-panels focusable multiple>
      <v-expansion-panel v-if="sanctions_ofac" class="mb-2">
        <v-expansion-panel-header>Lista OFAC - Papyrus</v-expansion-panel-header>
        <v-expansion-panel-content>
          <SanctionsOfac :avalId="sanctions_ofac" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="sanctions_csnu" class="mb-2">
        <v-expansion-panel-header>Lista Terrorismo CSNU</v-expansion-panel-header>
        <v-expansion-panel-content>
          <SanctionsCsnu :avalId="sanctions_csnu" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="terrorListEU" class="mb-2">
        <v-expansion-panel-header>Lista Terrorismo EU</v-expansion-panel-header>
        <v-expansion-panel-content>
          <TerrorListEU :avalId="terrorListEU" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="PEPBigDataCorp" class="mb-2">
        <v-expansion-panel-header>PEP Big Data Corp Completo</v-expansion-panel-header>
        <v-expansion-panel-content>
          <SanctionsBigDataCorp :avalId="PEPBigDataCorp" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="CGUPeps" class="mb-2">
        <v-expansion-panel-header>Consulta de PEP - Papyrus (CGU)</v-expansion-panel-header>
        <v-expansion-panel-content>
          <CGUPeps :avalId="CGUPeps" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="PEPPapyrus" class="mb-2">
        <v-expansion-panel-header>Busca Relacionamento PEP - Papyrus</v-expansion-panel-header>
        <v-expansion-panel-content>
          <PEPPapyrus :avalId="PEPPapyrus" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import dateFmt from '../mixins/dateFmt'
import displayStatus from '../mixins/displayStatus'

import PXSummary from './PXSummary'
import Avaliacoes from './Avaliacoes'
import SanctionsOfac from './SanctionsOfac'
import SanctionsCsnu from './SanctionsCsnu'
import TerrorListEU from './TerrorListEU'
import SanctionsBigDataCorp from './SanctionsBigDataCorp'
import CGUPeps from './CGUPeps'
import PEPPapyrus from './PEPPapyrus'

export default {
  components: {
    PXSummary, Avaliacoes, SanctionsOfac, SanctionsCsnu, TerrorListEU, SanctionsBigDataCorp, CGUPeps, PEPPapyrus,
  },

  props: {
    analiseid: Number,
  },

  mixins: [dateFmt, displayStatus],

  created () {
    this.init()
  },

  destroyed () {
    // clearInterval(this.reloader)
  },

  data: () => ({
    loading: true,
    error: null,
    reloader: null,
    sanctions_ofac: null,
    sanctions_csnu: null,
    terrorListEU: null,
    PEPBigDataCorp: null,
    CGUPeps: null,
    PEPPapyrus: null,

  }),

  computed: {
    ...mapState(['analises','avaliacoes','account',]),

    analise: function() {
      return this.$store.getters['analises/getAnaliseById'](parseInt(this.analiseid))
    },

    finished: function() {
      // return !this.loading && this.analise.pxdata && !this.analise.pxdata.summary.pending_report_list.length
      return !this.loading && this.analise.pxdata
    },

    displayNome: function() {
      if(!this.analise.pxdata) {
        return ''
      }

      if ('nome_set' in this.analise.pxdata.summary &&
                    this.analise.pxdata.summary.nome_set.length > 0) {
        return ' - ' + this.analise.pxdata.summary.nome_set[0].value
      } else if ('razaosocial_set' in this.analise.pxdata.summary &&
                    this.analise.pxdata.summary.razaosocial_set.length > 0) {
        return ' - ' + this.analise.pxdata.summary.razaosocial_set[0].value
      }

      return ''

    },
  },

  methods: {
    init: function(){
      this.fetchData()
    },

    async fetchPxdata () {
      if (!this.analise.pxdata) {
        this.loading = true
        await this.$store.dispatch('analises/get_analise_pxdata', this.analiseid)
        this.loading = false
      }
    },

    async fetchData () {
      this.loading = true
      await this.$store.dispatch('analises/get_analise', this.analiseid)
      this.loading = false
    },

    dataFilter() {
      const labelSanctionsOfac = 'Lista OFAC'
      const labelSanctionsCsnu = 'Lista Terrorismo CSNU'
      const labelTerrorListEU = 'Lista Terrorismo EU'
      const labelPEPBigDataCorp = 'PEP (BigDataCorp)'
      const labelCGUPeps = 'Pessoa Exposta Politicamente'
      const labelPEPPapyrus = 'PEP Relacionados (BigDataCorp)'
      
      if (this.avaliacoes && this.avaliacoes.avaliacoes) {
        for (let i = 0; i < this.avaliacoes.avaliacoes.length; i++) {
          const aval = this.avaliacoes.avaliacoes[i]
          
          if (aval.aval_task_status !== 'CP') {
            continue
          }
          
          if (aval.nome_modelo === labelSanctionsOfac) {
            this.sanctions_ofac = aval.id
          }
          else if (aval.nome_modelo === labelSanctionsCsnu) {
            this.sanctions_csnu = aval.id
          }
          else if (aval.nome_modelo === labelTerrorListEU) {
            this.terrorListEU = aval.id
          }
          else if (aval.nome_modelo === labelPEPBigDataCorp) {
            this.PEPBigDataCorp = aval.id
          }
          else if (aval.nome_modelo === labelCGUPeps) {
            this.CGUPeps = aval.id
          }
          else if (aval.nome_modelo === labelPEPPapyrus) {
            this.PEPPapyrus = aval.id
          }
        }
      }
    }
  },

};
</script>
