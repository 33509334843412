export default {
  methods: {
    displaySummaryKey: function(key) {
      switch(key) {
        case 'nome_set':
          return 'Nome'
        case 'registrogeral_set':
          return 'RG'
        case 'genero_set':
          return 'Gênero'
        case 'escolaridade_set':
          return 'Escolaridade'
        case 'datadenascimento_set':
          return 'Data de Nascimento'
        case 'pais':
          return 'Pais'
        case 'filhos':
          return 'Filhos'
        case 'dependentes_set':
          return 'Dependentes'
        case 'estadocivil_set':
          return 'Estado Civil'
        case 'renda_set':
          return 'Renda Anual'
        case 'dividas_set':
          return 'Dívidas'
        case 'protestos_set':
          return 'Protestos'
        case 'avaliacaodecredito_set':
          return 'Avaliação de Crédito'
        case 'endereco_set':
          return 'Endereço'
        case 'telefone_set':
          return 'Telefone'
        case 'sociedade_set':
          return 'Sociedades'
        case 'situacaomei_set':
          return 'MEI'
        case 'capitalsocial_set':
          return 'Capital Social'
        case 'razaosocial_set':
          return 'Razão Social'
        case 'nomefantasia_set':
          return 'Nome Fantasia'
        case 'datadeabertura_set':
          return 'Data de Abertura'
        case 'participacoes':
          return 'Participações'
        case 'participadoras':
          return 'Participadoras'
        case 'cnae_set':
          return 'CNAE'
        case 'situacaosimples_set':
          return 'Simples'
        case 'faturamento_set':
          return 'Faturamento Anual'
        case 'email_set':
          return 'E-Mails'
        case 'irmao_set':
          return 'Irmãos'
        case 'porte_set':
          return 'Porte'
        case 'naturezajuridica_set':
          return 'Natureza Jurídica'
        default:
          return key
      }
    },
  },
}
