import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import AnalisePLD from './views/AnalisePLD.vue'
import Modelo from './views/Modelo.vue'

Vue.use(Router)

function castAnalisePLDID(route) {
  return {
    analiseid: Number(route.params.analiseid),
  };
}

function castModeloID(route) {
  return {
    modeloid: Number(route.params.modeloid),
  };
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', name: 'home', component: Home },
    { path: '/analise/:analiseid', name: 'analise', component: AnalisePLD, props: castAnalisePLDID },
    { path: '/modelo/:modeloid', name: 'modelo', component: Modelo, props: castModeloID },
  ]
})
