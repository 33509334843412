import axios from 'axios'

const state = {
  modelos: {
    list: [],
    prev: null,
    next: null,
    pg: null,
    tot_pgs: null,
  },
  analistas: {
    list: [],
    prev: null,
    next: null,
    pg: null,
    tot_pgs: null,
  }
}

const getters = {
  getModeloById: (state) => (id) => {
    let x = state.modelos.list.find(mod => mod.id === id)
    if(!x) {
      x = state.analistas.list.find(mod => mod.id === id)
    }
    return x
  },
  getModeloByURL: (state) => (url) => {
    let x = state.modelos.list.find(mod => mod.url === url)
    if(!x) {
      x = state.analistas.list.find(mod => mod.url === url)
    }
    return x
  },
}

const mutations = {
  clear_modelos (state) {
    state.modelos.list = []
    state.modelos.prev = null
    state.modelos.next = null
    state.modelos.pg = null
    state.modelos.tot_pgs = null
  },

  set_modelos (state, { results, prev, next, pg, tot }) {
    state.modelos.list = results
    state.modelos.prev = prev
    state.modelos.next = next
    state.modelos.pg = pg
    state.modelos.tot_pgs = tot
  },

  add_modelo (state, result) {
    let idx = state.modelos.list.findIndex(mod => mod.id === result.id)
    if (idx !== -1) {
      state.modelos.list.splice(idx, 1, result)
    } else {
      state.modelos.list.push(result)
    }
  },

  clear_analistas (state) {
    state.analistas.list = []
    state.analistas.prev = null
    state.analistas.next = null
    state.analistas.pg = null
    state.analistas.tot_pgs = null
  },

  set_analistas (state, { results, prev, next, pg, tot }) {
    state.analistas.list = results
    state.analistas.prev = prev
    state.analistas.next = next
    state.analistas.pg = pg
    state.analistas.tot_pgs = tot
  },

  add_analista (state, result) {
    let idx = state.analistas.list.findIndex(anl => anl.id === result.id)
    if (idx !== -1) {
      state.analistas.list.splice(idx, 1, result)
    } else {
      state.analistas.list.push(result)
    }
  },
}

const actions = {
  async get_modelos ({ commit, rootGetters }, url) {
    if (!url) {
      url = process.env.VUE_APP_API_URL + `/modelospld/?tipo=C`
    }

    let response = await axios
      .get(url,
        { headers: rootGetters['account/getAuthHeader'], },
      )

      await commit('set_modelos',
        { 'results': response.data.results,
          'prev': response.data.previous,
          'next': response.data.next,
          'pg': response.data.page,
          'tot': response.data.total_pages,
        })
  },

  async new_modelo ({ commit, rootGetters },
      { nome, params, tipo, ativo, modulo }) {

    let data = {
      nome: nome,
      parametros: params,
      tipo_de_modelo: tipo,
      ativo: ativo,
      modulo: modulo,
    }

    let response = await axios
      .post(process.env.VUE_APP_API_URL + `/modelospld/`,
        data,
        { headers: rootGetters['account/getAuthHeader'], }
      )

    await commit('add_modelo', response.data)
    return response.data
  },

  async get_analistas ({ commit, rootGetters }, url) {
    if (!url) {
      url = process.env.VUE_APP_API_URL + `/modelospld/?tipo=M`
    }

    let response = await axios
      .get(url,
        { headers: rootGetters['account/getAuthHeader'], },
      )

      await commit('set_analistas',
        { 'results': response.data.results,
          'prev': response.data.previous,
          'next': response.data.next,
          'pg': response.data.page,
          'tot': response.data.total_pages,
        })
  },

  async getResource ({ rootGetters }, url) {
    let response = await axios
      .get(url,
        { headers: rootGetters['account/getAuthHeader'], }
      )

    return response.data
  },

  async get_modelo ({ dispatch, commit }, url) {
    let response = await dispatch('getResource', url)
    await commit('add_modelo', response)
    return response
  },

  async get_modelo_by_id ({ dispatch, commit }, id) {
    let url = process.env.VUE_APP_API_URL + `/modelospld/${id}/`
    let response = await dispatch('getResource', url)

    if(response.tipo_de_modelo === 'M') {
      await commit('add_analista', response)
    } else {
      await commit('add_modelo', response)
    }

    return response
  },

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
