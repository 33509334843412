<template>
  <div>
    <v-progress-circular v-if="isLoading || !avalData" indeterminate class="mt-2"/>
    <div v-else>
      <v-card v-if="avalData && avalData.contents && avalData.contents.length > 0" elevation="3" class="pa-1 mt-2">
        <v-simple-table dense >
          <thead>
            <tr>
              <th>Nome</th>
              <th>Relação</th>
              <th>Criado em</th>
              <th>Todas Informações</th>
              <th>URL</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(c, i) in avalData.contents" :key="i">
              <td>{{ c.name }}</td>
              <td>{{ c.ratio  }}</td>
              <td>{{ c.created_at ? formattedDate(c.created_at) : '' }}</td>
              <td>
                <showModal 
                  title="Conteúdo"
                  :list="c.full_info"
                  button="Mostrar"
                />
              </td>
              <td>{{ c.url  }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
      <v-card v-else>
      <v-simple-table dense class="mt-2">
        <thead>
          <tr>
            <th>
              Nada consta
            </th>
          </tr>
        </thead>
      </v-simple-table>
    </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import dateFmt from '../mixins/dateFmt'
import showModal from '../components/Modal.vue'

export default {
  props: {
    avalId: Number,
  },

  mixins: [
    dateFmt
  ],

  computed: {
    ...mapState(['avaliacoes'])
  },

  components: {
    showModal
  },

  data() {
    return {
      isLoading: true,
      avalData: null
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    init () {
      this.isLoading = true
      this.avalData = null

      this.$store.dispatch('avaliacoes/get_data', this.avalId, 'Lista OFAC').then((data) => {
        this.avalData = data
        this.isLoading = false
      })
    } 
  }
}
</script>
