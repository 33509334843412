<template>
  <v-menu
    v-model="menu"
    offset-y
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator">
        <v-btn text v-on="on">
          {{ account.username }}
        </v-btn>
      </slot>
    </template>
    <v-card>
      <v-list>
        <v-list-item @click="logout">
          <v-list-item-action>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState([
    'account'
  ]),

  components: {
  },

  props: {
  },

  data() {
    return {
      menu: false,
    }
  },

  methods: {
    logout: function() {
      this.$store.dispatch('account/logout')
    },
  },
}
</script>
