<template>
  <v-menu
    open-on-hover
  >
    <template v-slot:activator="{ on }">
      <span v-on="on">{{ title }}</span>
    </template>
      <v-list dense>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">{{ title }}</v-list-item-title>
            <v-list-item-subtitle>Total: {{ reports.length }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="reports.length"></v-divider>
        <v-list-item v-for="r in reports" :key="r.url">
          <v-list-item-title>
            <v-icon :color="displayStatusColor(r.status)" class="mr-1">{{ displayStatusIcon(r.status) }}</v-icon> {{ r.label }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
  </v-menu>
</template>

<script>
import reportStatus from '../mixins/reportStatus'

export default {
  props: {
    title: String,
    reports: Array,
  },

  mixins: [reportStatus],

};
</script>
