import Vue from 'vue'
import Vuex from 'vuex'

import account from './modules/account'
import analises from './modules/analises'
import modelos from './modules/modelos'
import avaliacoes from './modules/avaliacoes'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account: account,
    analises: analises,
    modelos: modelos,
    avaliacoes: avaliacoes,
  },
})
