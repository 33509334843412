<template>
    <v-card>
      <v-card-title>
        Parâmetros do Modelo
      </v-card-title>
      <v-card-text>
        <v-simple-table dense v-if="stringKeys.length > 0">
          <tbody>
            <tr
              v-for="(r, i) in stringKeys"
              :key="i + '-string'"
            >
              <td>{{ r }}</td>
              <td>{{ params[r] }}</td>
            </tr>
          </tbody>
        </v-simple-table>
    <v-row
      v-for="(r, k) in objKeys"
      :key="k + '-obj'">
      <v-col>
        <v-card>
          <v-card-title>
            {{ r }}
          </v-card-title>
          <v-card-text>
            <ModeloParams :params="params[r]"></ModeloParams>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    params: Object,
  },

  computed: {
    paramsKeys: function() {
      let dontshow = ['url']
      return Object.keys(this.params).filter(i => dontshow.indexOf(i) === -1)
    },
    stringKeys: function() {
      return this.paramsKeys.filter(r => !(this.params[r] instanceof Object))
    },
    objKeys: function() {
      return this.paramsKeys.filter(r => this.params[r] instanceof Object)
    },
  },
};
</script>
