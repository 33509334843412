<template>
  <div>
    <v-progress-circular v-if="isLoading || !avalData" indeterminate class="mt-2"/>
    <div v-else>
      <div v-if="avalData && avalData[0] && avalData[0].contents && avalData[0].contents.length > 0 && avalData[1] && avalData[1].contents && avalData[1].contents.length > 0">
        <v-card v-if="avalData && avalData[0] && avalData[0].contents && avalData[0].contents.length > 0" elevation="3" class="pa-1 mt-2">
          <v-simple-table dense >
            <thead>
              <tr>
                <th>ID</th>
                <th>Data de nascimento</th>
                <th>Nome</th>
                <th>Sobrenome</th>
                <th>Link imagem</th>
                <th>Link self</th>
                <th>Link miniatura</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(c, i) in avalData[0].contents" :key="i">
                <td>{{ c.entity_id  }}</td>
                <td>{{ c.date_of_birth  }}</td>
                <td>{{ c.forename  }}</td>
                <td>{{ c.name  }}</td>
                <td>{{ c._links.images ? c._links.images.href : '-' }}</td>
                <td>{{ c._links.self ? c._links.self.href : '-' }}</td>
                <td>{{ c._links.thumbnail ? c._links.thumbnail.href : '-' }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
        <v-card v-if="avalData && avalData[1] && avalData[1].contents && avalData[1].contents.length > 0" elevation="3" class="pa-1 mt-2">
          <v-simple-table dense >
            <thead>
              <tr>
                <th>ID</th>
                <th>Data de nascimento</th>
                <th>Nome</th>
                <th>Relação</th>
                <th>Ocorrências</th>
                <th>URL</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(c, i) in avalData[1].contents" :key="i">
                <td>{{ c.entity_id  }}</td>
                <td>{{ c.date_of_birth  }}</td>
                <td>{{ c.name  }}</td>
                <td>{{ c.ratio }}</td>
                <td>
                  <showModal 
                    title="Conteúdo"
                    :list="c.occurences"
                    button="Mostrar"
                  />
                </td>
                <td>{{ c.url }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </div>
      <v-card v-else>
      <v-simple-table dense class="mt-2">
        <thead>
          <tr>
            <th>
              Nada consta
            </th>
          </tr>
        </thead>
      </v-simple-table>
    </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import dateFmt from '../mixins/dateFmt'
import showModal from '../components/Modal.vue'

export default {
  props: {
    avalId: Number,
  },

  mixins: [
    dateFmt
  ],

  computed: {
    ...mapState(['avaliacoes'])
  },
  
  components: {
    showModal
  },

  data() {
    return {
      isLoading: true,
      avalData: null
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    init () {
      this.isLoading = true
      this.avalData = null
      
      this.$store.dispatch('avaliacoes/get_data', this.avalId, 'Lista Terrorismo CSNU').then((data) => {
        this.avalData = data
        this.isLoading = false
      })
    } 
  }
}
</script>
