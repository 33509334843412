<template>
  <v-card>
    <v-overlay
      v-if="obfuscado"
      absolute
      opacity="1"
      color="white"
      light
      z-index="4"
    >
      <span class="indigo--text title">Avalie o pedido para ver este dado</span>
    </v-overlay>
    <v-card-title>
      <span v-if="avaliacao.definitivo">
        <v-icon
          color="yellow darken-2"
          class="mr-2"
        >mdi-shield-star-outline
      </v-icon>Avaliação Definitiva (
      </span>
        {{ avaliacao.nome_modelo }}
      <span v-if="avaliacao.definitivo">)</span>
      <v-spacer />
      <NovaAvaliacao
        v-if="!avaliacao.definitivo"
        :avaliacao="avaliacao"
        :analise="analise"
        :PEP="avaliacao.isPEPAval"
      />
    </v-card-title>
    <v-card-text>
      <span v-if="avaliacao.comentario">
        <ComentarioPEPRelatives
        v-if="is_google_pep_relative"
        :comentario="avaliacao.comentario"
        :nome="nome"
        />
        <div
        v-else>
        {{ avaliacao.comentario }}
        </div>
      </span>
      <div class="text-center">
        <span v-if="avaliacao.isPEPAval && avaliacao.resultado === 'R'" class="red--text">É PEP</span>
        <v-icon x-large color="green" v-if="avaliacao.resultado === 'A'">mdi-thumb-up-outline</v-icon>
        <v-icon x-large color="red" v-if="avaliacao.resultado === 'R'">mdi-thumb-down-outline</v-icon>
        <span v-if="avaliacao.risco === 'B'"><v-icon x-large color="green" >mdi-alert-circle-check-outline</v-icon> Risco Baixo</span>
        <span v-if="avaliacao.risco === 'M'"><v-icon x-large color="yellow" >mdi-alert</v-icon> Risco Médio</span>
        <span v-if="avaliacao.risco === 'A'"><v-icon x-large color="red" >mdi-alert-octagon</v-icon> Risco Alto</span>
        <v-icon x-large color="yellow darken-2" v-if="avaliacao.resultado === 'I'">mdi-file-question-outline</v-icon>
        <v-icon x-large color="orange darken-2" v-if="avaliacao.resultado === 'P'">mdi-file-document-edit-outline</v-icon>
        <v-icon x-large color="grey" v-if="!avaliacao.resultado">mdi-clock-outline</v-icon>
      </div>
      <div class="text-center" v-if="avaliacao.editor">
        Editado por {{ avaliacao.editor }} em {{ formattedDate(avaliacao.editado) }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import NovaAvaliacao from './NovaAvaliacao'
import dateFmt from '../mixins/dateFmt'
import ComentarioPEPRelatives from './ComentarioPEPRelatives.vue'

export default {
  components: {
    NovaAvaliacao, ComentarioPEPRelatives,
  },

  mixins: [dateFmt],

  props: {
    avaliacao: Object,
    analise: Object,
    obfuscado: Boolean,
    nome: String,
  },

  computed: {
    is_google_pep_relative() {
      return /Há pessoas na lista de PEPs da CGU que compartilham sobrenomes com a pessoa avaliada/i.test(this.avaliacao.comentario)
    },
  },
};
</script>
