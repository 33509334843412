<template>
  <v-dialog
    v-model="dialog"
    width="600"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
        <v-btn text v-on="on">
          Entrar
        </v-btn>
      </slot>
    </template>
    <v-card class="elevation-12">
      <v-toolbar>
        <v-toolbar-title>Entrar</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <div v-for="(err, idx) in non_field_errors" :key="`fruit-${idx}`">
          <v-alert type="error">
            {{ err }}
          </v-alert>
        </div>
        <v-form>
          <v-text-field
            v-model="username"
            prepend-icon="mdi-account"
            name="login"
            label="Usuário"
            type="text"
            :error-messages="errors.username"
            @input="errors.username = null"
          >
          </v-text-field>
          <v-text-field
            v-model="password"
            prepend-icon="mdi-lock"
            name="password"
            label="Senha"
            type="password"
            :error-messages="errors.password"
            @input="errors.password = null"
          >
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="login"
          >
          Entrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
  },

  data() {
    return {
      dialog: false,
      username: null,
      password: null,
      errors: {},
      non_field_errors: null,
    }
  },

  methods: {
    login: function() {
        this.errors = {}
        this.non_field_errors = null

        this.$store.dispatch('account/login',
          { username: this.username,
            password: this.password })
        .then(() => {
          this.dialog = false
        })
        .catch((error) => {
          this.errors = error.response.data
          this.non_field_errors = error.response.data.non_field_errors
        })
    },
  },
}
</script>
