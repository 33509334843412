import axios from 'axios'

const state = {
  analises: [],
  analises_prev: null,
  analises_next: null,
  analises_pg: null,
  analises_tot_pgs: null,
}

const getters = {
  getAnaliseById: (state) => (id) => {
    return state.analises.find(op => op.id === id)
  },
}

const mutations = {
  clear_analises (state) {
    state.analises = []
    state.analises_prev = null
    state.analises_next = null
    state.analises_pg = null
    state.analises_tot_pgs = null
  },

  set_analises (state, { results, prev, next, pg, tot }) {
    state.analises = results
    state.analises_prev = prev
    state.analises_next = next
    state.analises_pg = pg
    state.analises_tot_pgs = tot
  },

  add_analise (state, result) {
    let idx = state.analises.findIndex(op => op.id === result.id)
    if (idx !== -1) {
      state.analises.splice(idx, 1, result)
    } else {
      state.analises.push(result)
    }
  },
}

const actions = {
  async get_analises ({ commit, rootGetters }, url) {
    if (!url) {
      url = process.env.VUE_APP_API_URL + `/pedidosdeanalisepld/`
    }

    let response = await axios
      .get(url,
        { headers: rootGetters['account/getAuthHeader'], },
      )

      await commit('set_analises',
        { 'results': response.data.results,
          'prev': response.data.previous,
          'next': response.data.next,
          'pg': response.data.page,
          'tot': response.data.total_pages,
        })
  },

  async getResource ({ rootGetters }, url) {
    let response = await axios
      .get(url,
        { headers: rootGetters['account/getAuthHeader'], }
      )

    return response.data
  },

  async get_analise_pxdata ({ dispatch, commit }, id) {
    let url = process.env.VUE_APP_API_URL + `/pedidosdeanalisepld/${id}/?pxdata=1`
    let response = await dispatch('getResource', url)
    await commit('add_analise', response)
    return response
  },

  async get_analise ({ dispatch, commit }, id) {
    let url = process.env.VUE_APP_API_URL + `/pedidosdeanalisepld/${id}/`
    let response = await dispatch('getResource', url)
    await commit('add_analise', response)
    return response
  },

  async reaval_analise ({ rootGetters }, id) {
    let url = process.env.VUE_APP_API_URL + `/pedidosdeanalisepld/${id}/reavaliar/`
    let response = await axios
      .post(url,
        null,
        { headers: rootGetters['account/getAuthHeader'], }
      )

    return response.data
  },

  async retry_failed_reports_analise ({ rootGetters }, id) {
    let url = process.env.VUE_APP_API_URL + `/pedidosdeanalisepld/${id}/retry_failed_reports/`
    let response = await axios
      .post(url,
        null,
        { headers: rootGetters['account/getAuthHeader'], }
      )

    return response.data
  },


}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
